<script lang="ts" setup>

</script>

<template lang="pug">
.instruction-step.step-2.grid.grid-cols-2.gap-6
  .image.flex.items-center
    NuxtImg(
      src="/images/screen-preview-hero.png"
      alt="Screenshot: E-Mail & Kennzeichen"
      loading="lazy"
      width="480"
      style="aspect-ratio: 580 / 371;"
    )

  .content
    .step-indicator.w-12.h-12.flex.items-center.justify-center.text-2xl.font-bold.text-white.bg-slate-500.rounded-full
      | 2

    h2 Daten
    p.
      Zur digitalen Abwicklung Ihrer Zulassung müssen Sie bestimmte Daten aus den bereitgelegten Dokumenten eingeben.

    p.
      Dieser Schritt dauert ungefähr 5 Minuten. In jedem Schritt wird Ihnen genau erklärt, welche Daten Sie eingeben müssen
      und wo Sie diese Daten finden (mit Bildern zum besseren Verständnis). Unsere Eingabemaske ist sehr einfach und übersichtlich gestaltet,
      damit Sie sich schnell zurechtfinden.
</template>

<style lang="sass">
.instruction-step.step-2
  .image img
    width: 480px
    height: auto
</style>
