<script lang="ts" setup>

</script>

<template lang="pug">
.instruction-step.step-2.grid.grid-cols-2.gap-6
  .image
    NuxtImg(
      src="/images/foto-ident.jpg"
      alt="Foto Ident"
      loading="lazy"
      width="480"
      style="width: 480px; aspect-ratio: 376 / 251; height: auto;"
    ).rounded-lg.drop-shadow-lg

  .content
    .step-indicator.w-12.h-12.flex.items-center.justify-center.text-2xl.font-bold.text-white.bg-slate-500.rounded-full
      | 4

    h2 Foto-Ident
    p.
      Zum Abschluss Ihrer digitalen Zulassung müssen Sie sich per Foto-Ident verifizieren. Hierzu machen Sie nur ein
      Foto Ihres Personalausweises und ein Selfie. Die Identifizierung ist intuitiv und einfach, 24/7 verfügbar und
      dauert nur wenige Minuten.

    p.
      Nach erfolgreicher Identifizierung müssen Sie noch die notwendigen Zulassungsdokumente digital unterzeichnen. Dazu erhalten Sie
      im Prozess einen Code per SMS, den Sie eingeben müssen.
</template>

<style lang="sass">

</style>
