<script lang="ts" setup>
const { umschreibung  } = await usePrice()
</script>

<template lang="pug">
.instruction-step.step-1.grid.grid-cols-2.gap-6
  .content
    .step-indicator.w-12.h-12.flex.items-center.justify-center.text-2xl.font-bold.text-white.bg-slate-500.rounded-full
      | 3

    h2 Zahlung
    p.
      Um die Zulassung zu veranlassen müssen Sie verschiedene Gebühren begleichen. Unser Service kostet {{ umschreibung.asString }}. Inkludiert sind
      hiermit alle Gebühren, die für die Zulassung anfallen.

    p.
      Wir bieten die gängigsten Zahlungsmethoden an, um den Prozess zu erleichtern.

    p(style="font-size: 1rem;")
      strong
        | Zahlungsmethoden: Rechnung (Klarna), Kreditkarte, PayPal, Google Pay oder Apple Pay

  .image
    NuxtImg(
      src="/images/online-payment.jpg"
      alt="Online Payment"
      loading="lazy"
      width="480"
      style="width: 480px; aspect-ratio: 3755 / 2503; height: auto;"
    ).rounded-lg.drop-shadow-lg
</template>

<style lang="sass">

</style>
