<script lang="ts" setup>
</script>

<template lang="pug">
.instruction-step.step-1.grid.gap-6
  .content
    .step-indicator.w-12.h-12.flex.items-center.justify-center.text-2xl.font-bold.text-white.bg-slate-500.rounded-full
      | 1

    h2 Dokumente & Kennzeichen
    p.
      Legen Sie erst alles bereit, was Sie zur digitalen Zulassung brauchen. Sie benötigen einen gültigen Personalausweis
      (Reisepass ist nicht möglich) und die Zulassungsbescheinigung Teil I (Fahrzeugschein) und Teil II (Fahrzeugbrief).
    
    p.
      Außerdem benötigen Sie die aktuellen Kfz-Kennzeichen. Möchten Sie ein Wunschkennzeichen, müssen Sie dieses vorher
      reservieren. Sie können aber auch ein zufälliges Kennzeichen erhalten.

    p.
      Auch wichtig: Sie brauchen eine elektronische Versicherungsbestätigung (eVB-Nummer) von Ihrer Kfz-Versicherung.

  .image
    NuxtImg(
      src="/images/zulassungsbescheinigung-teil1.jpg"
      alt="Screenshot: E-Mail & Kennzeichen"
      loading="lazy"
      width="480"
      style="width: 480px; aspect-ratio: 376 / 251; height: auto;"
    ).rounded-lg.drop-shadow-lg
</template>

<style lang="sass">

</style>
