<script lang="ts" setup>
defineProps({
  cityName: {
    type: String,
    default: ''
  }
})

const { umschreibung  } = await usePrice()

const showQuestionableInfo = inject<boolean>('showQuestionableInfo', true)
</script>

<template lang="pug">
.advantages.flex.flex-wrap.gap-16.justify-center
  .advantage-card.flex.flex-col.justify-center
    img.icon(
      src="/images/icons/thumbs-up.svg"
      alt="Icon: Daumen hoch"
      loading="lazy"
    )
    p.title 100% Geld-zurück-Garantie
    p.content Sollte die Onlinezulassung aus irgendeinem Grund nicht klappen, erstatten wir Ihnen umgehend den vollen Betrag zurück.
  
  .advantage-card.flex.flex-col.justify-center
    img.icon(
      src="/images/icons/timer.svg"
      alt="Icon: Timer"
      loading="lazy"
    )
    p.title Sofort Anmelden & losfahren
    p.content Endlich ist es so weit: Sie möchten Ihr Fahrzeug anmelden? Mit zulassung.digital geht das ganz einfach und bequem von zu Hause aus. In nur wenigen Minuten erledigen Sie alle erforderlichen Schritte und können losfahren.

  .advantage-card.flex.flex-col.justify-center(
    v-if="showQuestionableInfo"
  )
    img.icon(
      src="/images/icons/walking.svg"
      alt="Icon: Gehen"
      loading="lazy"
    )
    p.title Kein Gang zur Zulassungsstelle
    p.content Sie müssen nicht mehr in die Zulassungsstelle fahren und dort in der Warteschlange stehen. Über zulassung.digital schließen Sie den Vorgang komplett digital ab.

  .advantage-card.flex.flex-col.justify-center
    img.icon(
      src="/images/icons/clock-outline.svg"
      alt="Icon: Uhr"
      loading="lazy"
    )
    p.title Unser Service ist 24/7 verfügbar
    p.content Sie haben rund um die Uhr Zugang zu unserem Service. Egal, ob Sie morgens um 7 Uhr oder abends um 22 Uhr zulassen möchten - unser Service ist für Sie verfügbar.

  .advantage-card.flex.flex-col.justify-center
    img.icon(
      src="/images/icons/money-square.svg"
      alt="Icon: Geld"
      loading="lazy"
    )
    p.title Transparente Kosten: {{ umschreibung.asString }}
    p.content Die Kfz-Zulassung kostet Sie {{ umschreibung.asString }}
    p.content Keine versteckten Kosten: Mit diesem Betrag sind alle offiziellen Gebühren abgedeckt.

  //- .advantage-card.flex.flex-col.justify-center
  //-   img.icon(
  //-     src="/images/icons/car-outline.svg"
  //-     alt="Icon: Auto"
  //-     loading="lazy"
  //-   )
  //-   p.title Partner des Kraftfahrt&shy;bundesamts (KBA)
  //-   p.content Abmeldung.digital ist einer der ausgewählten Partner des Kraftfahrtbundesamts (KBA) des ikfz 4 Projekts. Das i-Kfz 4 ist die vierte Stufe der digitalen Fahrzeugzulassung in Deutschland. Sie ermöglicht es Zulassungsprozesse komplette online durchzuführen.
</template>

<style lang="sass">
.advantages
  .advantage-card
    flex-basis: 100%
    @apply bg-slate-100 drop-shadow-lg p-8 rounded-2xl text-center 

    @screen md
      flex-basis: calc(50% - 2rem)

    @screen xl
      flex-basis: calc(33% - 3rem)

    .icon
      @apply h-16 w-16 mx-auto

    .title
      @apply text-2xl font-bold mt-4

    .content
      @apply text-lg mt-2
</style>
