<script lang="ts" setup>
const email = useRuntimeConfig().public.email
</script>

<template lang="pug">
.faq-section.grid.grid-cols-1.gap-12(class="lg:grid-cols-5 lg:gap-20")
  .content(class="lg:flex lg:flex-col lg:justify-center lg:col-span-2")
    h2.font-bold.text-5xl.mt-6 Häufig gestellte Fragen
    p.mt-4 Falls Sie weitere Fragen oder Anregungen haben, können Sie sich in diesem Bereich über bekannte Fragestellungen informieren.
    p.mt-3 Falls Sie keine Antwort finden, sind unsere Mitarbeiter auch telefonisch und per E-Mail ständig für Sie erreichbar

    p.mt-3.text-sky-700
      a(:href="`mailto:${email}`") {{ email }}

  div(class="lg:flex lg:justify-center lg:col-span-3")
    Faq.max-w-xl
</template>

<style lang="sass">

</style>
